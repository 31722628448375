import { Box, Grid, Typography } from '@truxweb/ux';
import { useEffect, useState } from 'react';
import classnames from 'classnames';
import { GetServerSidePropsContext } from 'next';
import Image from 'next/image';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useStyles } from '../pageTemplates/BasePage/BasePage.styles';
import { useTranslation } from 'next-i18next';

const PAGE_ID = '404';
const REQUIRED_NAMESPACES = ['common', PAGE_ID];

export const Custom404 = (): JSX.Element => {
  const { t } = useTranslation(REQUIRED_NAMESPACES);
  const classes = useStyles();
  const [homeLink, setHomeLink] = useState('https://www.truxweb.com');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHomeLink(window?.location?.origin);
    }
  }, [setHomeLink]);

  return (
    <Grid className={classnames(classes.container)} item style={{ flexGrow: 1 }}>
      <main
        className={classes.content}
        role="main"
        style={{
          alignItems: 'center',
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          left: 0,
          position: 'absolute',
          right: 0,
          top: 0,
        }}
      >
        <Grid
          alignItems="center"
          container
          direction="column"
          justifyContent="center"
          style={{
            backdropFilter: 'blur(12px)',
            background:
              'radial-gradient(100% 248.19% at 0% 3.61%, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.26) 100%)',
            borderRadius: '32px',
            boxSizing: 'border-box',
            height: 610,
            padding: 64,
            width: 960,
          }}
        >
          <Grid item>
            <Image
              alt={'404'}
              height={250}
              src={`/assets/images/truxii/truxii-404.svg`}
              width={196}
            />
          </Grid>

          <Grid item>
            <Grid container justifyContent="center">
              <Typography color="primary" variant="h3">
                {t('common:404Prompt')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Box ml={5} mt={3} pb={3}>
              <Typography style={{ fontSize: 20, lineHeight: '32px' }}>
                {t('common:404Description')}&nbsp;
                <a href={homeLink} style={{ color: '#0087CE', textDecoration: 'none' }}>
                  {t('common:404Link')}
                </a>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </main>
    </Grid>
  );
};

export const getStaticProps = async (context: GetServerSidePropsContext): Promise<any> => {
  const { locale } = context;

  const translations = await serverSideTranslations(locale, REQUIRED_NAMESPACES);

  return {
    props: {
      ...translations,
    },
  };
};

export default Custom404;
